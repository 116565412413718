import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import "./HomeBanner.css";
import { Banners } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { ShimmerThumbnail } from "react-shimmer-effects";

const HomeBanner = () => {
  const { IMG_URL } = useContext(Context);
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBanner = async () => {

    const res = await Banners();
    try {
      if (res?.success) {
        setBanner(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

    // try {
    //   const response = await getData(`/without-login/ed-tech/home/banner`);
    //   setData(response?.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // } finally {
    //   setLoading(false); 
    // }

  };


  useEffect(() => {
    getBanner();
  }, []);
  const BannerData = [
    {
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Banner/home-banner2.jpg",
    },
    {
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Banner/home-banner2.jpg",
    },
  ];



  return (
    <>
      <section className="Home-Banner">
        <div className="container-fluid p-0">
          {loading && <ShimmerThumbnail rounded />}
          {!loading && (
            <Swiper
              spaceBetween={30}
              // loop={true}
              centeredSlides={true}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              dots={true}
              modules={[Autoplay, Pagination]}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              className="mySwiper"
            >
              {banner.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>

                    <div className="image-container">
                      <img
                        className="Banner-image"
                        src={IMG_URL + slide?.image}
                        alt="Banner"
                      />
                    </div>

                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
