import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "./Veggies_Section_Card.css";
import Common_Button from "../Common_Button/Common_Button";
import { Link, useNavigate } from "react-router-dom";
import Heart_Component from "../Heart_Component/Heart_Component";

function Veggies_Section_Card({
  image,
  vegetableName,
  color,
  price,
  offprice,
  detailsLink,
  product,
  m_varient,
  variant,
}) {
  const [likedProducts, setLikedProducts] = useState(false); // State to track liked products

  console.log(product, "productssssssssssssssss");

  const handleLike = () => {
    setLikedProducts(!likedProducts);
  };

  console.log(variant, "variant");
  console.log(m_varient, "m_varient");

  const navigate = useNavigate();

  const handleNavigate = async () => {
    const id = localStorage.setItem("product_id", product?.id);

    // navigate('/vendor-page');

    navigate("/vendor-page");
    // window.location.href = '/vendor-page';
  };

  return (
    <div className="veggies-section mb-5">
      <Card className="veggies ">
        {/* <div className="background like-heart">
          <Button
            variant="light"
            className="button-holder"
            type="button"
            onClick={handleLike}
          >
            <FontAwesomeIcon
              icon={faHeart}
              className={likedProducts ? "heart-icon active" : "heart-icon"}
            />
          </Button>
        </div> */}

        <Heart_Component
          product={product}
          m_varient={product?.product_multiple_variants[0]}
          variant={variant}
        />
        <div className="image-main" onClick={handleNavigate}>
          <Link to={detailsLink}>
            <Card.Img src={image} className="electronic-img" />
          </Link>
        </div>

        <Card.Body>
          <div onClick={handleNavigate}>
            <p className="card-heading">{vegetableName}</p>
            <p className="color">{color}</p>
            <div className="cart-price-holder">
              <div className="price">
                <p className="color1 me-4">{price}</p>
                <p className="color3">{offprice}</p>
              </div>
              <div className="cart">
                <div className="background cart-btn">
                  <Button
                    variant="light"
                    className="button-holder"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Veggies_Section_Card;
