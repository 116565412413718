import React, { useState, useEffect, useContext } from "react";
import "./TwoPayment.css";
import { Form } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { UserGet } from "../../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../../utils/context";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

const TwoPayment = ({
  handlePrevious,
  handleNext,
  addressData,
  addressId,
  paymentMethod,
  setPaymentMethod,
}) => {
  const handleMethod = async (value) => {
    await setPaymentMethod(value);
  };

  const { setUserData, cartCount, cart } = useContext(Context);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <ScrollToTop />
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="adresmain">
            {/* <h3>Thank You for Trusting Us</h3> */}
            {/* <div className="adresss">
              <p className="primary">Contact Address</p>
              <p className="disha">{addressData?.name}</p>
              <p className="disha">
                {addressData?.name +
                  "," +
                  addressData?.flat_home_bldg +
                  "," +
                  addressData?.col_land_strt}
              </p>
              {addressData?.pincode?.name}
              <p className="primary">Contact Number :-</p>
              <p> {addressData?.mobile_number}</p>
            </div> */}
          </div>
          <div className="Choose_Payment_Option">
            <div className="heading-holder">
              <h4>Choose Payment Option</h4>
            </div>

            <div>
              <div>
                <div className="radio-btn-input-holder">
                  {cart?.[0]?.product?.cod_available === 1 && (
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      label="Cash on delivery"
                      value="cod"
                      defaultChecked={paymentMethod === "cod" || true}
                      onClick={() => handleMethod("cod")}
                    />
                  )}

                  <Form.Check // prettier-ignore
                    type="radio"
                    name="method"
                    label="Online"
                    value="online"
                    defaultChecked={paymentMethod === "online"}
                    onClick={() => handleMethod("online")}
                  />

                  <Form.Check // prettier-ignore
                    type="radio"
                    name="method"
                    label="Wallet"
                    value="wallet"
                    defaultChecked={paymentMethod === "wallet"}
                    onClick={() => handleMethod("wallet")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            <Common_Button
              onClick={handleNext}
              text="Save & Next"
              className=" save-btn"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoPayment;
