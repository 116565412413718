import { React, useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./PopularCategories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { popularCategory } from "../../../utils/apis/Product/Product";
const PopularCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  // const navigate= useNavigate();
  const handleNext = async (id) => {
    navigate(`/select-from-category/${id}`);
  };

  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);

  const [popularCategories, setPopularCategories] = useState([]);

  const getPopularCategory = async () => {
    setShimmerLoader(true);
    const res = await popularCategory();
    if (res?.success) {
      setPopularCategories(res?.data);
      setShimmerLoader(false);
    }
  };

  useEffect(() => {
    getPopularCategory();
  }, []);

  const navigate = useNavigate();
  const navigateToCategory = (id) => {
    navigate(`/select-from-category/${id}`);
  };
  return (
    <>
      <section className="Popular-Categories">
        <Container>
          <div className="Categories">
            <h1 className="title">Popular Categories</h1>
          </div>

          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            pagination={{
              dynamicBullets: true,
              clickable:true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            className="mySwiper"
          >
            <Link to="/select-from-category">
              {popularCategories?.map((item) => (
                <SwiperSlide onClick={() => handleNext(item?.id)}>
                  <Popular_Product_Card
                    className="main-section my-5"
                    id={item?.id}
                    content={item?.name}
                    image={IMG_URL + item?.image}
                    imgClassName="products"
                    textClassName="product-text"
                  />
                </SwiperSlide>
              ))}
            </Link>

            {popularCategories.length > 5 && (
              <div class="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
              </div>
            )}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default PopularCategories;
