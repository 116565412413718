import React, { useContext, useState, useEffect, useRef } from "react";
import "./ProductPage.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heart_Component from "../../../Common-Component/Heart_Component/Heart_Component";
import { useParams } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Context } from "../../../../utils/context";
library.add(fas);

const ProductPage = ({
  product,
  selectedVariant,
  selectedsubVariant,
  images,
}) => {
  console.log(selectedsubVariant, "selectedsubVariant");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSubImage, setSelectedSubImage] = useState(null);

  useEffect(() => {
    if (selectedVariant?.product_images) {
      setSelectedImage(selectedVariant?.product_images?.[0]?.image);
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedsubVariant?.product_multi_varient_images) {
      setSelectedImage(
        selectedsubVariant?.product_multi_varient_images?.[0]?.image
      );
    }
  }, [selectedsubVariant]);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };
  const handleSubImageClick = (subimageSrc) => {
    setSelectedSubImage(subimageSrc);
    setSelectedImage("");
  };

  useEffect(() => {
    {
      selectedVariant?.product_images &&
        !selectedImage &&
        setSelectedImage(selectedVariant?.product_images?.[0]?.image);
    }
  }, [selectedVariant, selectedImage]);

  useEffect(() => {
    {
      selectedsubVariant?.product_multiple_variants?.[0]
        ?.product_multi_varient_images &&
        !selectedImage &&
        setSelectedSubImage(
          selectedsubVariant?.product_multiple_variants?.[0]
            ?.product_multi_varient_images?.[0]?.image
        );
    }
  }, [selectedsubVariant, selectedSubImage]);

  const [isClicked, setIsClicked] = useState(false);

  const toggleHeartColor = () => {
    setIsClicked(!isClicked);
  };

  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    setCurrentImage(images && images.length > 0 ? images[0].image : "");
  }, [images]);

  // const [swiperInstance, setSwiperInstance] = useState(null);

  class Zoomable {
    constructor(element, config) {
      this.element = element;
      this.config = this._mergeConfig(config);

      console.log("this.element", this.element);

      const { initialZoom, minZoom, maxZoom } = this.config;

      this.zoomed = false;
      this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
      this.zoom = this.initialZoom;

      this.img = element.querySelector(".zoomable__img");

      this.img.draggable = false;
      this.element.style.setProperty("--zoom", this.initialZoom);

      this._addEventListeners();
    }

    static get Default() {
      return Default;
    }

    _addEventListeners() {
      this.element.addEventListener("mouseenter", () =>
        this._handleMouseover()
      );
      this.element.addEventListener("mousemove", (evt) =>
        this._handleMousemove(evt)
      );
      this.element.addEventListener("mouseleave", () => this._handleMouseout());
      this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
    }

    _handleMouseover() {
      if (this.zoomed) {
        return;
      }

      this.element.classList.add("zoomable--zoomed");
      this.zoomed = true;
    }

    _handleMousemove(evt) {
      if (!this.zoomed) {
        return;
      }

      const elPos = this.element.getBoundingClientRect();
      const percentageX = `${((evt.clientX - elPos.left) * 100) /
        elPos.width}%`;
      const percentageY = `${((evt.clientY - elPos.top) * 100) /
        elPos.height}%`;

      this.element.style.setProperty("--zoom-pos-x", percentageX);
      this.element.style.setProperty("--zoom-pos-y", percentageY);
    }

    _handleMouseout() {
      if (!this.zoomed) {
        return;
      }

      this.element.style.setProperty("--zoom", this.initialZoom);
      this.element.classList.remove("zoomable--zoomed");
      this.zoomed = false;
    }

    _handleWheel(evt) {
      if (!this.zoomed) {
        return;
      }

      evt.preventDefault();

      const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
      const { minZoom, maxZoom } = this.config;

      this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
      this.element.style.setProperty("--zoom", this.zoom);
    }

    _mergeConfig(config) {
      return {
        ...this.constructor.Default,
        ...(typeof config === "object" ? config : {}),
      };
    }
  }

  const Default = {
    initialZoom: 3,
    minZoom: 1.25,
    maxZoom: 4,
    zoomSpeed: 0.01,
  };

  const zoomableRef = useRef(null);

  useEffect(() => {
    const element = zoomableRef.current;
    if (!element) return;

    const zoomable = new Zoomable(element);

    return () => {
      element.removeEventListener("mouseenter", zoomable._handleMouseover);
      element.removeEventListener("mousemove", zoomable._handleMousemove);
      element.removeEventListener("mouseleave", zoomable._handleMouseout);
      element.removeEventListener("wheel", zoomable._handleWheel);
    };
  }, []);

  const { id } = useParams();

  const { IMG_URL, shimmerLoader } = useContext(Context);

  return (
    <section className="cctv-innerpage">
      <div className="container">
        <div className="row camera-sec">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
            {shimmerLoader ? (
              <ShimmerThumbnail height={250} rounded />
            ) : (
              <div className="card-sec">
                <Heart_Component
                  product={product}
                  variant={selectedVariant}
                  m_varient={selectedsubVariant}
                  // variant={
                  //   selectedVariant ? selectedVariant : selectedsubVariant[0]
                  // }
                />
                <div className="product-detail text-center" ref={zoomableRef}>
                  {selectedImage ? (
                    <img
                      className="product-img zoomable__img"
                      src={IMG_URL + selectedImage}
                      alt="Selected"
                    />
                  ) : (
                    <img
                      className="product-img zoomable__img"
                      src={IMG_URL + selectedSubImage}
                      alt="Selected "
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="camera-slider">
              <Swiper
                slidesPerView={"auto"}
                modules={[Autoplay]}
                spaceBetween={20}
                breakpoints={{
                  0: { slidesPerView: 3, spaceBetween: 10 },
                  380: { slidesPerView: 4 },
                  485: { slidesPerView: 4 },
                  575: { slidesPerView: 4 },
                  768: { slidesPerView: 5 },
                  992: { slidesPerView: 3 },
                  1024: { slidesPerView: 3 },
                  1200: { slidesPerView: 4 },
                  1440: { slidesPerView: 4 },
                  2000: { slidesPerView: 4 },
                }}
                navigation
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  loop: true,
                }}
                className="mySwiper"
              >
                {selectedVariant?.product_images?.map((imageSrc, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="camera-imgs"
                      onClick={() => handleImageClick(imageSrc?.image)}
                    >
                      <img
                        src={IMG_URL + imageSrc?.image}
                        className="inner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
                {/* {selectedsubVariant?.product_multiple_variants?.map(
                  (image, imgIndex) =>
                    image?.product_multi_varient_images?.map((value, index) => {
                      <SwiperSlide key={`${imgIndex}`}>
                        <div
                          className="camera-imgs"
                          onClick={() => handleSubImageClick(value?.image)}
                        >
                          <img
                            src={IMG_URL + value?.image}
                            className="inner-img"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>;
                    })
                )} */}

                {selectedsubVariant?.product_multi_varient_images?.map(
                  (image, imgIndex) => (
                    <SwiperSlide key={`${imgIndex}`}>
                      <div
                        className="camera-imgs"
                        onClick={() => handleSubImageClick(image?.image)}
                      >
                        <img
                          src={IMG_URL + image?.image}
                          className="inner-img"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  )
                  // ))
                )}
              </Swiper>

              {selectedVariant?.product_images > 4 && (
                <>
                  <div
                    className="main-slider-cctv"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <div className="arrow-back" />
                  </div>
                  <div
                    className="main-slider-cctv"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <div className="arrow-next" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPage;
