import React, { useContext, useEffect, useState } from "react";
import "./career-description.css";
import Form from "react-bootstrap/Form";
import {
  getSingleJobs,
  postApplicationForm,
} from "../../../utils/apis/common/Common";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";

const Career_description = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { id } = useParams();
  const [jobs, setJobs] = useState();
  const { getData, IMG_URL } = useContext(Context);
  const getCareerJobs = async () => {
    const res = await getSingleJobs(id);
    if (res?.success) {
      setJobs(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getCareerJobs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const [pdfUrl, setPdfUrl] = useState(
    "https://www.clickdimensions.com/links/TestPDFfile.pdf"
  );
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is PDF
      if (file.type === "application/pdf") {
        const fileUrl = URL.createObjectURL(file);
        setUploadedPdfUrl(fileUrl);
      } else {
        // Notify the user that only PDF files are allowed
        alert("Please upload a PDF file.");
      }
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("job_id", jobs.id);
    formData.append("name", data.name);
    formData.append("contact_no", data.contact_no);
    formData.append("email", data.email);
    formData.append("current_organization", data.current_organization);
    formData.append("current_ctc", data.current_ctc);
    formData.append("total_exp", data.total_exp);
    formData.append("current_location", data.current_location);
    formData.append("resume", data?.resume[0]);

    const res = await postApplicationForm(formData);
    if (res?.success) {
      setSuccessModal(true);
      setUploadedPdfUrl("");
      setTimeout(() => {
        setSuccessModal(false);
        reset();
      }, 3000);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  return (
    <>
      <section className="Career_description">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="sec-1">
                <div className="title-div text-left">
                  <h3 className="title">Job Description</h3>
                  <br></br>
                </div>

                <ul>
                  <li>Work Module : {jobs?.work_module?.name}</li>
                  <li>Job Type : {jobs?.job_type?.name}</li>
                  <li>
                    Job Location : {jobs?.state?.name}, {jobs?.city?.name}
                  </li>
                  <li>Department : {jobs?.department?.name}</li>
                  <li>
                    Experience :{jobs?.exp_from}-{jobs?.exp_to} Years
                  </li>
                  <li>Qualification : {jobs?.qualification?.name}</li>
                </ul>

                <div className="rem-cont">
                  <h5>Job Responsibilities:</h5>
                  {/* <ul>
                    <li>
                      Edit market research reports in accordance with business
                      language
                    </li>
                    <li>
                      Rephrase or paraphrase the content to bring in better
                      clarity
                    </li>
                    <li>
                      Discuss ambiguous and convoluted information with in-house
                      writers and produce logically structured content
                    </li>
                    <li>
                      Eliminate pompous frills and identify nonsensical words or
                      phrases
                    </li>
                    <li>
                      Ensure on-time delivery of reports, articles, marketing
                      collaterals, etc.
                    </li>
                    <li>
                      Provide effective and constructive feedback to the writers
                    </li>
                  </ul> */}
                  {htmlToReactParser.parse(jobs?.job_responsibilities)}

                  <h5>Requirements:</h5>
                  {/* <ul>
                    <li>Proficiency in English language</li>
                    <li>
                      Preferably a graduate/post-graduate in Mass Communication,
                      English Literature, and Journalism
                    </li>
                    <li>
                      Proven experience working under pressure to deliver
                      high-quality output in time
                    </li>
                    <li>Familiarity with MS Office</li>
                    <li>
                      Willingness to multitask and take up new initiatives
                    </li>
                    <li>Attention to detail</li>
                  </ul> */}
                  {htmlToReactParser.parse(jobs?.requirement)}
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="sec-2">
                <div className="text-center mb-4">
                  <h5>Application Form</h5>
                </div>

                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Candidate Name"
                      {...register("name", {
                        required: "Candidate Name is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">
                        {errors.name.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="Contact No."
                      {...register("contact_no", {
                        required: "Contact number is required",
                        validate: {
                          isTenDigits: (value) =>
                            (value && value.length === 10) ||
                            "Contact number must be 10 digits",
                          isNumeric: (value) =>
                            /^\d+$/.test(value) ||
                            "Contact number must be numeric",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                        "is-valid":
                          !errors?.contact_no &&
                          getValues("contact_no")?.length === 10,
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        if (
                          !/[0-9]/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                        if (
                          event.target.value?.length >= 10 &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.contact_no && (
                      <div className="invalid-feedback">
                        {errors.contact_no.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1 "
                  >
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="E-mail Address"
                      {...register("email", {
                        required: "Email Id required",
                        pattern: {
                          value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          message: "Email Id Invalid",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">
                        {errors.email.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="current_location"
                      placeholder="Enter Current location"
                      {...register("current_location", {
                        required: "Current location is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.current_location,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.current_location && (
                      <div className="invalid-feedback">
                        {errors.current_location.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="current_organization"
                      placeholder="Enter Current Organization"
                      {...register("current_organization", {
                        required: "Current Organization is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.current_organization,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.current_organization && (
                      <div className="invalid-feedback">
                        {errors.current_organization.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="number"
                      name="current_ctc"
                      placeholder="Enter Current CTC"
                      {...register("current_ctc", {
                        required: "Current CTC is required",
                        validate: (value) =>
                          value >= 0 || "Current CTC cannot be negative",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.current_ctc,
                      })}
                      onKeyDown={(event) => {
                        if (event.key === "-" || event.key === "e") {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.current_ctc && (
                      <div className="invalid-feedback">
                        {errors.current_ctc.message}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="number"
                      name="total_exp"
                      placeholder="Enter Total Experience"
                      {...register("total_exp", {
                        required: "Total Experience is required",
                        validate: (value) =>
                          value >= 0 || "Total Experience cannot be negative",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.total_exp,
                      })}
                      onKeyDown={(event) => {
                        if (event.key === "-" || event.key === "e") {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.total_exp && (
                      <div className="invalid-feedback">
                        {errors.total_exp.message}
                      </div>
                    )}
                  </Form.Group>

                  <div className="col-lg-12">
                    <div className="form-field mb-4">
                      <Form.Group controlId="Last_Name">
                        {/* <Form.Label className="required">
                            Upload Document
                          </Form.Label> */}
                        <div>
                          <Form.Label>Upload Document</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.resume,
                            })}
                            type="file"
                            {...register("resume", {
                              validate: async (value) => {
                                if (typeof value !== "string") {
                                  const fileTypes = ["pdf"];
                                  const fileType = value[0]?.name?.split(
                                    "."
                                  )[1];

                                  if (!fileTypes.includes(fileType)) {
                                    return `please upload a valid file format. (${fileTypes})`;
                                  }
                                }
                              },
                            })}
                            accept=".pdf"
                            multiple={false}
                            onChange={handleFileUpload}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {/* <Form.Label>View Document</Form.Label> */}
                    <div className="">
                      {/* <Form.Control
                        type="text"
                        className="me-3"
                        value={adminPdfName}
                        disabled
                      /> */}
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="view-pdf-holder">
                          {uploadedPdfUrl && (
                            <iframe
                              className="pdf-holder"
                              src={uploadedPdfUrl}
                              title="Uploaded PDF"
                            ></iframe>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="btn-holder">
                  <Common_Button
                    className="SubmitBtn"
                    text="Proceed"
                    padding="7px 20px"
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Success...! Application Form Sent Successfully"}
      />
    </>
  );
};

export default Career_description;
