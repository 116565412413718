import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./MobileOtpModal.css";

function MobileOtpModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [verifyStat, setVerifyStat] = useState(true);

  const handleOtpChange = (index, value) => {
    if (!isNaN(value) && value !== "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 3) {
        setVerifyStat(true);
        document.getElementById(`otpInput-${index + 1}`).focus();
      } else {
        setVerifyStat(false);
      }
    } else if (value === "") {
      setVerifyStat(true);
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        document.getElementById(`otpInput-${index - 1}`).focus();
      }
    }
  };

  const [isTimerExpired, setIsTimerExpired] = useState(false);

  useEffect(() => {
    const decrementTimer = () => {
      if (props.seconds > 0) {
        setIsTimerExpired(false);
        props.setSeconds(props.seconds - 1);
      } else {
        setIsTimerExpired(true);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [props?.seconds]);

  useEffect(() => {
    setOtp(["", "", "", ""]);
    setVerifyStat(true);
    setIsTimerExpired(false);
  }, [props.show]);

  return (
    <>
      <section className="MobileOtpModal">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="MobileOtpModal"
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <Modal.Title id="contained-modal-title-vcenter">
              Check Your Otp
              <p>We have sent Otp on your Phone Number</p>
            </Modal.Title>

            <div className="otp_input">
              {otp.map((digit, index) => (
                <input
                  className="opt-input-field"
                  key={index}
                  id={`otpInput-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                />
              ))}
            </div>

            <div className="get-otp">
              <p className="otp">
                Enter OTP within{" "}
                {`${Math.floor(props.seconds / 60)}:${(props.seconds % 60)
                  .toString()
                  .padStart(2, "0")}`}{" "}
                seconds
              </p>
              <p className="otp text text-danger">{props.otpErr}</p>
              <p>
                {/* Didn't get the OTP Resend After Timeout ?{" "} */}
                {props?.seconds == 0 && (
                  <span
                    onClick={() => {
                      props.handleSendOtp()
                      setOtp(["", "", "", ""])
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Click to Resend.
                  </span>
                )}
              </p>
            </div>

            <div className="row mt-4">
              {/* <div className="col-lg-6">
                <div className="text-center">
                  <button type="button" className="mb-2 Cancelbtn" onClick={props.onHide}>
                    Cancel
                  </button>
                </div>
              </div> */}
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="text-center">
                  {verifyStat ||
                    (!isTimerExpired && (
                      <button
                        type="button"
                        className="mb-2 Verify"
                        onClick={() => props.verifyOTP("mobile", otp.join(""))}
                      >
                        Verify{" "}
                      </button>
                    ))}
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default MobileOtpModal;
