import React, { useState, useEffect, useContext } from "react";
import "./Blog.css";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import { getAllBlog } from "../../utils/apis/common/Common";
import Blog_vdo from "./Blog-banner/Blog_vdo";

const Blog = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);
  const [recentpost, setRecentpost] = useState([]);
  const { getData, IMG_URL } = useContext(Context);

  const getProject = async () => {
    const res = await getAllBlog();
    if (res?.success) {
      setBlog(res?.data?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const getProjectTwo = async () => {
    const res = await getAllBlog();
    if (res?.success) {
      setRecentpost(res?.data?.recent_post);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const BlogDetails = async (id) => {
    navigate(`/blog-details/${id}`);
  };

  useEffect(() => {
    getProject();
    getProjectTwo();
  }, []);

  return (
    <>
      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {blog?.map((post, index) => (
                <Card key={index}>
                  <Row>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <div className="image-holder">
                        <img
                          src={IMG_URL + post?.image}
                          className="blog-img"
                          alt="Blog"
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Card.Body>
                        <div className="text-holder">
                          <p>
                            {post?.createdAt && (
                              <>
                                {new Date(post?.createdAt).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </>
                            )}
                          </p>
                          <h3>{post?.name}</h3>
                          <p> {htmlToReactParser.parse(post?.description)} </p>
                          <div className="read-more-align">
                            <p
                              className="readmore-p"
                              type="button"
                              onClick={() => {
                                BlogDetails(post?.id);
                              }}
                            >
                              Read More{" "}
                              <FaArrowRight className="fa-sharp fa-solid fa-arrow-right readmorefafa" />
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>
            <div className="col-lg-4">
              <Blog_vdo />
              <div className="recent-post-div">
                <h6 className="title">Recent Posts</h6>
                <div className="blog-card-holder">
                  {recentpost?.map((post, index) => (
                    <div key={index} className="row mb-3">
                      <div className="col-xxl-3 col-lg-4 col-md-2 col-sm-2 col-3">
                        <div>
                          <img
                            className="recent-post-img"
                            src={IMG_URL + post?.image}
                            alt="Recent Post"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-9 col-lg-8 col-md-10 col-sm-10 col-9">
                        <div className="mt-2">
                          <h6 className="post-title">{post?.name}</h6>
                          <p className="post-date">
                            {post?.createdAt && (
                              <>
                                {new Date(post?.createdAt).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;