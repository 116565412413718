import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "./SelectFromCategories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Product_Card from "../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import Select from "react-select";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import {
  Category,
  ChildSubCategory,
  GetAllBrands,
  Products,
  SubCategory,
} from "../../utils/apis/Product/Product";
import Slider from "@mui/material/Slider";
library.add(fas);

const SelectFromCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [numToShow, setNumToShow] = useState(20); // Number of products to show initially
  const [showAll, setShowAll] = useState(false);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState();

  const [childCategory, setChildCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchByBrands, setSearchByBrands] = useState("");

  const { IMG_URL, shimmerLoader, setShimmerLoader, Select2Data } = useContext(
    Context
  );
  const { id } = useParams();
  useEffect(() => {
    setCategoryId(id);
  }, [id]);
  const getBrands = async () => {
    const response = await GetAllBrands();
    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const getCategory = async () => {
    try {
      setShimmerLoader(true);
      const res = await Category();
      if (res?.success) {
        getProduct(id);
        setSubCategory(id);
        setCategory(res.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setShimmerLoader(false);
    }
  };

  const getSubCategory = async (categoryId) => {
    setShimmerLoader(true);
    const res = await SubCategory(categoryId);
    if (res?.success) {
      setSubCategory(res.data || []);
      await setSubCategoryId(res.data?.[0]?.id);
    }
    setShimmerLoader(false);
  };

  const getChildCategory = async (subCategoryId) => {
    setShimmerLoader(true);
    const res = await ChildSubCategory(subCategoryId);
    if (res?.success) {
      setChildCategory(res.data);
      setShimmerLoader(false);
    }
  };

  const getProduct = async () => {
    setShimmerLoader(true);
    const res = await Products(
      categoryId,
      subCategoryId,
      searchByBrands?.value,
      sortOrder
      // sortOrderAsc
    );
    if (res?.success) {
      setProducts(res?.data?.data);
      setShimmerLoader(false);
    }
  };

  const handleCategoryClick = async (categoryId) => {
    await getSubCategory(categoryId);
    await setCategoryId(categoryId);
  };

  const handleSubCategoryClick = async (subCategoryId) => {
    await setSubCategoryId(subCategoryId);
  };

  const handlebrandChange = async (brand_id) => {
    await setSearchByBrands(brand_id);
  };

  const [sortOrder, setSortOrder] = useState();
  // const [sortOrderAsc, setSortOrderAsc] = useState();

  const handlePriceHighToLow = async (value) => {
    await setSortOrder(value);
    // await setSortOrderAsc("");
  };

  // const handlePriceLowTohigh = async (value) => {
  //   await setSortOrderAsc(value);
  //   // await setSortOrder("");
  // };

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setNumToShow(showAll ? 10 : products?.length);
  };

  useEffect(() => {
    getCategory();
    getBrands();
  }, [id]);

  // const [priceRange, setPriceRange] = useState();
  // const handleRangeChange = async (range) => {
  //   await setPriceRange(range);
  // };
  useEffect(() => {
    getProduct();
  }, [categoryId, subCategoryId, searchByBrands, sortOrder]);
  return (
    <section className="select-categories">
      <div className="container-lg">
        <div className="row">
          <div className="col-md-3 leftfilterdiv">
            <h2 className="title">Filter Category</h2>

            <p className="ortspace">Filter by Brand</p>

            <div className="filterdivv">
              <div className="row mb-lg-5 mb-2">
                <div className="col-12 mb-3">
                  <div className="num ">
                    <Select
                      isSearchable
                      options={brands}
                      value={searchByBrands}
                      placeholder="Select Brand"
                      onChange={(e) => {
                        handlebrandChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-6  mb-3">
                  <p className="ortspace">Sort by Price</p>
                  <div className="num ">
                    <label className="mb-1 ballll">High to Low</label>
                    <input
                      className="ms-3"
                      type="radio"
                      name="sortOrder"
                      value="DESC"
                      // checked={sortOrder === "DESC"}
                      onClick={() => handlePriceHighToLow("DESC")}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-6  mb-3">
                  <div className="num ">
                    <label className="mb-1 ballll">Low To High</label>
                    <input
                      className="ms-3"
                      type="radio"
                      name="sortOrder"
                      value="DESC"
                      // checked={sortOrderAsc === "ASC"}
                      onClick={() => handlePriceHighToLow("ASC")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div>
              <div className="mb-4">
                <h2 className="title">All Category</h2>
              </div>

              <Tab.Container id="left-tabs-example" defaultActiveKey={id}>
                <Row className="me-0 ms-0 ">
                  <div variant="pills" className="flex-row p-0 mb-3">
                    <Swiper
                      className="mySwiper1 "
                      loop={true}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      // navigation={true}

                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                      breakpoints={{
                        0: { slidesPerView: 1.5, spaceBetween: 10 },
                        420: { slidesPerView: 2, spaceBetween: 10 },
                        768: { slidesPerView: 3.5, spaceBetween: 10 },
                        992: { slidesPerView: 4.5, spaceBetween: 10 },
                        1200: { slidesPerView: 5.5, spaceBetween: 10 },
                      }}
                    >
                      {category?.map((slide, index) => (
                        <SwiperSlide key={index} className="product-slide">
                          <Nav.Item>
                            <Nav.Link
                              eventKey={slide?.id}
                              onClick={() => handleCategoryClick(slide?.id)} // Fetch subcategories and products on click
                            >
                              <Popular_Product_Card
                                className="product-image"
                                content={slide?.name}
                                image={IMG_URL + slide?.image}
                                imgClassName="category-image"
                              />
                              <div className="triangle-right"></div>
                            </Nav.Link>
                          </Nav.Item>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  {subCategory?.length > 0 && (
                    <>
                      <h2 className="title">All Sub Category</h2>
                      <div variant="pills" className="flex-row p-0  mb-3">
                        <Swiper
                          className="mySwiper1 "
                          breakpoints={{
                            0: { slidesPerView: 1.5, spaceBetween: 10 },
                            420: { slidesPerView: 2, spaceBetween: 10 },
                            768: { slidesPerView: 3.5, spaceBetween: 10 },
                            992: { slidesPerView: 4.5, spaceBetween: 10 },
                          }}
                        >
                          {Array.isArray(subCategory) &&
                            subCategory.map((slide, index) => (
                              <SwiperSlide
                                key={index}
                                className="product-slide"
                              >
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={slide?.id}
                                    onClick={() =>
                                      handleSubCategoryClick(slide?.id)
                                    }
                                  >
                                    <Popular_Product_Card
                                      className="product-image"
                                      content={slide?.name}
                                      image={IMG_URL + slide?.image}
                                      imgClassName="category-image"
                                    />
                                    <div className="triangle-right"></div>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </>
                  )}

                  {/* Products Section */}
                  <div className="desktop_view_product_category_slider slider-holder">
                    <Row>
                      {products?.length > 0 ? (
                        products.slice(0, numToShow).map((product, index) => {
                          const price = product?.product_multiple_mains?.[0]
                            ?.product_multiple_variants?.[0]?.s_price
                            ? product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants?.[0]?.s_price
                            : product?.product_variants?.[0]?.s_price;

                          return (
                            <Col
                              key={index}
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                            >
                              <Product_Card
                                image={IMG_URL + product?.image1}
                                text={product?.name}
                                product_id={product?.id}
                                subText={product?.short_description}
                                price={price}
                                mrp={
                                  product?.product_variants?.[0]?.mrp
                                    ? product?.product_variants?.[0]?.mrp
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.mrp
                                }
                                newest={product?.newest}
                                variant_id={product?.product_variants?.[0]?.id}
                                m_variant_id={
                                  product?.product_multiple_mains?.[0]
                                    ?.product_multiple_variants[0]?.id
                                }
                                tradediscount={
                                  product?.product_variants?.[0]?.trade
                                    ? product?.product_variants?.[0]?.trade
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.trade
                                }
                                schemediscount={
                                  product?.product_variants?.[0]?.scheme
                                    ? product?.product_variants?.[0]?.scheme
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.scheme
                                }
                                rupeesdiscount={
                                  product?.product_variants?.[0]?.discount
                                    ? product?.product_variants?.[0]?.discount
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.discount
                                }
                                percent={
                                  product?.product_variants?.[0]?.percent
                                    ? product?.product_variants?.[0]?.percent
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.percent
                                }
                                rupees={
                                  product?.product_variants?.[0]?.rupees
                                    ? product?.product_variants?.[0]?.rupees
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.rupees
                                }
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <div className="empty-heading-holder">
                          <h3>No Product With This Category</h3>
                        </div>
                      )}
                    </Row>
                  </div>

                  {/* mobile_view_product_category_slider start */}

                  <div className="mobile_view_product_category_slider">
                    <Row className="ms-0 me-0">
                      <Swiper
                        className="select-categories-slider mySwiper"
                        modules={[Pagination]}
                        pagination={{
                          dynamicBullets: true,
                        }}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          420: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          486: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          576: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          992: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                        }}
                      >
                        {products?.length > 0 ? (
                          products.slice(0, numToShow).map((product, index) => {
                            const price = product?.product_multiple_mains?.[0]
                              ?.product_multiple_variants?.[0]?.s_price
                              ? product.product_multiple_mains[0]
                                  .product_multiple_variants[0].s_price
                              : product?.product_variants?.[0]?.s_price;

                            return (
                              <SwiperSlide key={index}>
                                <Product_Card
                                  image={IMG_URL + product?.image1}
                                  text={product?.name}
                                  product_id={product?.id}
                                  subText={product?.short_description}
                                  price={price}
                                  mrp={
                                    product?.product_variants?.[0]?.mrp
                                      ? product?.product_variants?.[0]?.mrp
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]?.mrp
                                  }
                                  newest={product?.newest}
                                  m_variant_id={
                                    product?.product_multiple_mains?.[0]
                                      ?.product_multiple_variants?.[0]?.id
                                  }
                                  variant_id={
                                    product?.product_variants?.[0]?.id
                                  }
                                  tradediscount={
                                    product?.product_variants?.[0]?.trade
                                      ? product?.product_variants?.[0]?.trade
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]?.trade
                                  }
                                  schemediscount={
                                    product?.product_variants?.[0]?.scheme
                                      ? product?.product_variants?.[0]?.scheme
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]?.scheme
                                  }
                                  rupeesdiscount={
                                    product?.product_variants?.[0]?.discount
                                      ? product?.product_variants?.[0]?.discount
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]
                                          ?.discount
                                  }
                                  percent={
                                    product?.product_variants?.[0]?.percent
                                      ? product?.product_variants?.[0]?.percent
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]
                                          ?.percent
                                  }
                                  rupees={
                                    product?.product_variants?.[0]?.rupees
                                      ? product?.product_variants?.[0]?.rupees
                                      : product?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]?.rupees
                                  }
                                />
                              </SwiperSlide>
                            );
                          })
                        ) : (
                          <Col xs={12} className="mt-5">
                            <p>No products available</p>
                          </Col>
                        )}
                      </Swiper>
                    </Row>
                  </div>

                  {/* View All/ View Less Button */}
                  {(numToShow < products?.length || showAll) && (
                    <div className="view-all mb-5" onClick={toggleShowAll}>
                      <p>{showAll ? "View Less" : "View All"}</p>
                    </div>
                  )}
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelectFromCategories;
