import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

function Stepthree(props) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm();

  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "s_commission_details",
  });

  // Watch specific fields
  const sCommissionDetails = watch("s_commission_details", []);
  const companyNames = sCommissionDetails.map((data) => data?.company_name);
  const takePercent = sCommissionDetails.map((data) => data?.take_percent);
  const givePercent = sCommissionDetails.map((data) => data?.give_percent);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Prepare the array of commission details
    const commissionDetails = data.s_commission_details.map((mainVariant) => ({
      // id: data?.id ? data?.id : null,
      user_id: user_id,
      company_name: mainVariant?.company_name,
      take_percent: mainVariant?.take_percent,
      give_percent: mainVariant?.give_percent,
    }));

    // Append the entire array to the FormData
    formData.append("s_commission_details", JSON.stringify(commissionDetails));

    try {
      const res = await postData("/seller/s-commission_details", formData);
      if (res?.success) {
        props.nextStep();
        // navigate("/"); // Uncomment this if you want to navigate after successful submission
      } else {
        console.error("Failed to submit data");
      }
    } catch (error) {
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(
      `/seller/s-commission_details/?user_id=${user_id}`
    );
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    if (user_id) {
      getEditData();
    }
  }, [user_id]);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form userForm">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="details-form-holder">
            <div className="form-container text-center">
              <FontAwesomeIcon
                icon="fa-solid fa-triangle-exclamation"
                className="trianglealertmark"
              />

              {fields.map((field, index) => (
                <div key={field.id} className="col-md-6">
                  {/* Dynamic message for each entry */}
                  <p>
                    I am getting{" "}
                    <strong>{`${sCommissionDetails[index]?.take_percent ||
                      "0"}%`}</strong>{" "}
                    from{" "}
                    <strong>
                      {`(${sCommissionDetails[index]?.company_name || "N/A"})`}
                    </strong>{" "}
                    and I can give{" "}
                    <strong>
                      {`${sCommissionDetails[index]?.give_percent || "0"}%`}
                    </strong>{" "}
                    from my profit margin.
                  </p>

                  <div className="field-bottom">

                    <Form.Group className="form_group">
                      <Form.Label className="required">
                        Front Company Name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={`s_commission_details[${index}].company_name`}
                        placeholder="Company Name"
                        {...register(
                          `s_commission_details[${index}].company_name`,
                          {
                            required: "Company Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message:
                                "Company Name should only contain letters and spaces",
                            },
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          }
                        )}
                        maxLength={maxCharLimit}
                        onChange={handleInputChange}
                        onKeyDown={(event) => {
                          const { key } = event;
                          if (
                            !/^[A-Za-z\s]+$/.test(key) &&
                            key !== "Backspace" &&
                            key !== "Tab"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        className={classNames("", {
                          "is-invalid":
                            errors?.s_commission_details?.[index]?.company_name,
                          "is-valid":
                            !errors?.s_commission_details?.[index]
                              ?.company_name &&
                            getValues(
                              `s_commission_details[${index}].company_name`
                            ),
                        })}
                      />


                      <div className="d-flex justify-content-between">

                        <div>
                          {errors.s_commission_details?.[index]?.company_name && (
                            <span className="text-danger">
                              {
                                errors.s_commission_details?.[index]?.company_name
                                  .message
                              }
                            </span>
                          )}{" "}
                        </div>

                        <div>
                          <div className="text-end">
                            <small>{maxCharLimit} characters Limit </small>
                          </div>
                        </div>

                      </div>



                    </Form.Group>



                    <Form.Group className="form_group">
                      <Form.Label className="required">
                        Incoming Profit*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={`s_commission_details[${index}].take_percent`}
                        placeholder="Take Percent"
                        {...register(
                          `s_commission_details[${index}].take_percent`,
                          {
                            required: "Incoming Profit is required",
                            pattern: {
                              value: /^[1-9][0-9]?$|^100$/, // Ensures only 1-100
                              message: "Only numbers from 1 to 100 are allowed",
                            },
                          }
                        )}
                        className={classNames("", {
                          "is-invalid":
                            errors?.s_commission_details?.[index]?.take_percent,
                          "is-valid":
                            !errors?.s_commission_details?.[index]
                              ?.take_percent &&
                            getValues(
                              `s_commission_details[${index}].take_percent`
                            ),
                        })}
                        onKeyDown={(event) => {
                          const { key } = event;
                          const value = event.target.value;

                          // Allow control keys: Backspace, Tab, Delete, Arrow keys
                          if (
                            [
                              "Backspace",
                              "Tab",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ].includes(key)
                          ) {
                            return;
                          }

                          // Prevent non-numeric characters
                          if (!/[0-9]/.test(key)) {
                            event.preventDefault();
                          }

                          // Prevent leading zero (e.g., 000)
                          if (value === "0") {
                            event.preventDefault();
                          }

                          // Prevent values greater than 100
                          if (parseInt(value + key) > 100) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Ensure the value stays within 1-100 range and remove invalid characters
                          let inputValue = event.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric
                          if (
                            inputValue !== "" &&
                            (parseInt(inputValue) < 1 ||
                              parseInt(inputValue) > 100)
                          ) {
                            event.target.value = inputValue.slice(0, -1); // Remove last character if out of range
                          }
                        }}
                      />
                      {errors.s_commission_details?.[index]?.take_percent && (
                        <span className="text-danger">
                          {
                            errors.s_commission_details?.[index]?.take_percent
                              .message
                          }
                        </span>
                      )}
                    </Form.Group>


                    <Form.Group className="form_group">
                      <Form.Label className="required">
                        Profit I Can Give*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name={`s_commission_details[${index}].give_percent`}
                        placeholder="Give Percent"
                        {...register(
                          `s_commission_details[${index}].give_percent`,
                          {
                            required: "Out Going Profit is required",
                            pattern: {
                              value: /^[1-9][0-9]?$|^100$/, // Ensures only 1-100
                              message: "Only numbers from 1 to 100 are allowed",
                            },
                          }
                        )}
                        className={classNames("", {
                          "is-invalid":
                            errors?.s_commission_details?.[index]?.give_percent,
                          "is-valid":
                            !errors?.s_commission_details?.[index]
                              ?.give_percent &&
                            getValues(
                              `s_commission_details[${index}].give_percent`
                            ),
                        })}
                        onKeyDown={(event) => {
                          const { key } = event;
                          const value = event.target.value;

                          // Allow control keys: Backspace, Tab, Delete, Arrow keys
                          if (
                            [
                              "Backspace",
                              "Tab",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ].includes(key)
                          ) {
                            return;
                          }

                          // Prevent non-numeric characters
                          if (!/[0-9]/.test(key)) {
                            event.preventDefault();
                          }

                          // Prevent leading zero (e.g., "000", "012")
                          if (value === "0") {
                            event.preventDefault();
                          }

                          // Prevent values greater than 100
                          if (parseInt(value + key) > 100) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Ensure value stays within 1-100 and remove invalid characters
                          let inputValue = event.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric
                          if (
                            inputValue !== "" &&
                            (parseInt(inputValue) < 1 ||
                              parseInt(inputValue) > 100)
                          ) {
                            event.target.value = inputValue.slice(0, -1); // Remove last character if out of range
                          }
                        }}
                      />
                      {errors.s_commission_details?.[index]?.give_percent && (
                        <span className="text-danger">
                          {
                            errors.s_commission_details?.[index]?.give_percent
                              .message
                          }
                        </span>
                      )}

                    </Form.Group>
                    <Button variant="danger ms-2" onClick={() => remove(index)}>
                      Remove
                    </Button>
                  </div>
                </div>
              ))}

              <Button className="addcompbtn" onClick={() => append({})}>
                Add Company
              </Button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
}

export default Stepthree;
