import React, { useState, useEffect, useContext } from "react";
import "./Information.css";
import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { RegxExpression } from "../../../../utils/common";
import { UserGet, UserEdit } from "../../../../utils/apis/myAccount/myAccount";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import UpdateSuccess from "../../../Common_modal/UpdateSuccess/UpdateSuccess";
import { Context } from "../../../../utils/context";
import MyAccount from "../../MyAccount";

function Information() {
  const [editStatus, setEditStatus] = useState(true);
  const { postData, getData, IMG_URL, getDimension, setUserData } = useContext(
    Context
  );
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/account-information"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  const [modalShow, setModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    const finalData = new FormData();
    finalData.append("first_name", data?.first_name);
    finalData.append("contact_no", data?.contact_no);
    finalData.append("dob", data?.dob);
    finalData.append("email", data?.email);
    finalData.append("image", data?.image[0]);
    const res = await UserEdit(finalData);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        setEditStatus(true);
      }, 3000);
    } else {
      if (res?.message?.both) {
        setError("email", { message: res?.message?.both });
        setError("contact_no", { message: "" });
      }
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };

  const handleEdit = async () => {
    setEditStatus(!editStatus);
    getUser();
  };

  // const [userData, setUserData] = useState();
  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);

      reset(res?.data);
      setSelectedDate(res?.data?.dob ? new Date(res.data.dob) : null);
      setProfileImage(res?.data?.profileImage); // Set profile image from API response
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setProfileImage(URL.createObjectURL(file));
  };

  return (
    <>
      <section className="account-information my-account my-account-main ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item> */}
              <Breadcrumb.Item active>
                <Link to={"/information"}>Personal Information </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          {/* <div className="back-text-holder">
            <Link to={"/information"}>
              <p>Back</p>
            </Link>
          </div> */}

          <MyAccount />

          <div>
            <h2 className="title">Personal Information</h2>
          </div>

          <div className="form-section">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 mb-3">
                <div className="account-form">
                  <div className="row mb-3">
                    <div className="col-md-10 col-10">
                      <h4 className="form-title">Account Details</h4>
                    </div>
                    <div className="col-md-2 col-2 text-end">
                      <div>
                        <h6 className="edit" onClick={handleEdit}>
                          Edit
                        </h6>
                      </div>
                    </div>
                  </div>
                  <Form>
                    <Col lg={12}>
                      <div className="image-preview-container mt-3 text-center">
                        {typeof getValues("image") === "string" ? (
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview rounded"
                            style={{ radius: "100px", height: "100px" }}
                          />
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <img
                              src={URL?.createObjectURL(imageFile[0])}
                              alt="Preview"
                              className="image-preview rounded"
                              style={{ borderRadius: "100px", height: "100px" }}
                            />
                          )
                        )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="main-form-section mt-3 mb-3">
                        <Row className="justify-content-center">
                          <Col lg={4}>
                            <Form.Label column sm="4" className="label-set">
                              Profile*
                            </Form.Label>
                          </Col>

                          <Col lg={12}>
                            <div className="main-form-section mt-3 mb-3">
                              <Row className="justify-content-center">
                                <Col lg={4}>
                                  <Form.Label
                                    column
                                    sm="4"
                                    className="label-set"
                                  >
                                    Profile*
                                  </Form.Label>
                                </Col>

                                <Col lg={8}>
                                  <Form.Group>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.image,
                                      })}
                                      type="file"
                                      {...register("image", {
                                        validate: (value) => {
                                          if (
                                            typeof getValues("image") ===
                                              "string" ||
                                            value.length > 0
                                          ) {
                                            return true;
                                          }
                                          return "Profile image is required";
                                        },
                                      })}
                                      disabled={editStatus}
                                      accept=".jpg, .jpeg, .png"
                                      multiple={false}
                                    />
                                  </Form.Group>
                                  {errors.image && (
                                    <span className="text-danger">
                                      {errors.image.message}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "First name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name && !editStatus,
                            "is-valid": getValues("first_name") && !editStatus,
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.first_name && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger  ">
                              {errors?.first_name?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Mobile Number*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no && !editStatus,
                            "is-valid": getValues("contact_no") && !editStatus,
                          })}
                          type="text"
                          name="contact_no"
                          placeholder="Enter Mobile Number"
                          {...register("contact_no", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                        {errors?.contact_no && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger  ">
                              {errors?.contact_no?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Date Of Birth
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          control={control}
                          name="dob"
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <DatePicker
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              onChange={(date) => {
                                field.onChange(format(date, "yyyy-MM-dd"));
                                setSelectedDate(date);
                              }}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="Select Date"
                              maxDate={new Date()}
                              className={classNames("form-control", {
                                "is-invalid": errors?.dob && !editStatus,
                                "is-valid": getValues("dob") && !editStatus,
                              })}
                              disabled={editStatus}
                            />
                          )}
                        />
                        {errors?.dob && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger">
                              {errors?.dob?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Email
                      </Form.Label>
                      <Col sm="8">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Control
                            disabled={editStatus}
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email && !editStatus,
                              "is-valid": getValues("email") && !editStatus,
                            })}
                          />

                          {errors?.email && (
                            <div className="mt-md-0 mt-sm-3 mt-3">
                              <sup className="text-danger  ">
                                {errors?.email?.message}
                              </sup>
                            </div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {/* <span style={{ color: "green", fontSize: "1.2em" }}>
                              ✔
                            </span> */}
                          </div>
                        </div>
                      </Col>

                      {getValues("membership") && (
                        <>
                          <div className="congrats_text  mt-3 text-center">
                            <h3>Congratulations </h3>
                          </div>
                          <label
                            style={{ marginTop: "10px", textAlign: "center" }}
                          >
                            You Have Been Updated To{" "}
                            <strong>{getValues("membership")}</strong>{" "}
                            Membership
                          </label>{" "}
                        </>
                      )}
                    </Form.Group>

                    {!editStatus && (
                      <div className="btn-holder text-center">
                        <Common_Button
                          className={"SubmitBtn"}
                          text={"Update"}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpdateSuccess
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Data Updated Successfully"}
      />
    </>
  );
}

export default Information;
