import React, { useState, useEffect, useContext } from "react";
import "./AboutBanner.css";
import { Context } from "../../../utils/context";
import { bannerLelo } from "../../../utils/apis/common/Common";
import { ShimmerThumbnail } from "react-shimmer-effects";


const AboutBanner = () => {
  const [getBanner, setBanner] = useState();
  const [loading, setLoading] = useState(true);
  const { getData, IMG_URL } = useContext(Context);

  const getProject = async () => {

    const res = await bannerLelo();
    try {
      if (res?.success) {
        setBanner(res?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProject();
  }, []);




  return (
    <>
      <section className="about-banner">
        <div className="container">
          {loading && <ShimmerThumbnail  rounded />}
          {!loading && (
            <div className="row">
              <div className="col-md-12 mx-auto mt-4">
                <div className="banner">
                  {/* <img src={process.env.PUBLIC_URL + "/assets/Image/about/about-banner.png"} className="about-banner" /> */}
                  <img
                    src={IMG_URL + getBanner?.image}
                    className="about-banner"
                  />
                </div>
              </div>
            </div>
          )}

        </div>
      </section>
    </>
  );
};

export default AboutBanner;
