import { React, useState, useEffect, useContext } from "react";
import "./SecondVendor.css";
import { Container, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import { Context } from "../../../utils/context";
import { Products } from "../../../utils/apis/Product/Product";
import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const SecondVendor = ({ heading, product }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const { IMG_URL } = useContext(Context);
  const [addCartModal, setAddCartModal] = useState(false);
  const [products, setProduct] = useState([]);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleProductClick = (productId) => {
    localStorage.setItem("product_id", productId);
    window.location.reload();
  };

  const getProducts = async () => {
    const res = await Products(product?.category_id, "", "");
    if (res?.success) {
      setProduct(res?.data?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [product]);

  return (
    <>
      <section className="Frequently-Bought">
        <Container>
          <h1 className="title">{heading}</h1>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
              1900: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
            className="mySwiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {products.length > 0 ? (
              products.map((product, index) => {
                const price = product?.product_multiple_mains?.[0]
                  ?.product_multiple_variants?.[0]?.s_price
                  ? product.product_multiple_mains[0]
                      .product_multiple_variants[0].s_price
                  : product?.product_variants?.[0]?.s_price;

                return (
                  <SwiperSlide key={index}>
                    <div onClick={() => handleProductClick(product?.id)}>
                      <Product_Card
                        image={IMG_URL + product?.image1}
                        text={product?.name}
                        product_id={product?.id}
                        subText={product?.short_description}
                        price={price}
                        mrp={
                          product?.product_variants?.[0]?.mrp
                            ? product?.product_variants?.[0]?.mrp
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.mrp
                        }
                        newest={product?.newest}
                        variant_id={product?.product_variants?.[0]?.id}
                        tradediscount={
                          product?.product_variants?.[0]?.trade
                            ? product?.product_variants?.[0]?.trade
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.trade
                        }
                        schemediscount={
                          product?.product_variants?.[0]?.scheme
                            ? product?.product_variants?.[0]?.scheme
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.scheme
                        }
                        rupeesdiscount={
                          product?.product_variants?.[0]?.discount
                            ? product?.product_variants?.[0]?.discount
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.discount
                        }
                        m_variant_id={
                          product?.product_multiple_mains?.[0]
                            ?.product_multiple_variants[0]?.id
                        }
                        percent={
                          product?.product_variants?.[0]?.percent
                            ? product?.product_variants?.[0]?.percent
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.percent
                        }
                        rupees={
                          product?.product_variants?.[0]?.rupees
                            ? product?.product_variants?.[0]?.rupees
                            : product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants[0]?.rupees
                        }
                      />
                    </div>
                  </SwiperSlide>
                );
              })
            ) : (
              <Col xs={12} className="mt-5">
                <p>No products available</p>
              </Col>
            )}
          </Swiper>

          <SupermarketModal
            show={addCartModal}
            onHide={() => setAddCartModal(false)}
          />
          <div className="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SecondVendor;
