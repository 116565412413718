import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import "./couponmodal.css";
import { Coupons } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";

const CoupanModal = ({ show, handleClose, setCopyCode }) => {
  const { IMG_URL } = useContext(Context);
  const [coupons, setCoupons] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchCoupons = async () => {
      const res = await Coupons();
      if (res?.success) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set the time to the start of the day
        const validCoupons = res.data.filter((item) => {
          const expirationDate = new Date(item.e_date);
          expirationDate.setHours(0, 0, 0, 0);
          console.log(expirationDate, "expirationDate");

          return expirationDate >= today;
        });
        setCoupons(validCoupons);
      }
    };
    fetchCoupons();
  }, []);

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        setCopyCode(code);

        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => {
        // Handle copy failure
        console.error("Copy failed:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      className="couponmoddd"
    >
      <Modal.Header closeButton>
        <Modal.Title>Coupons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {coupons.map((item, index) => (
            <div
              key={index}
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 mb-2"
            >
              <div className="card coupancard">
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <div className="main-images">
                        <div className="image">
                          <img
                            className="card-img"
                            src={`${IMG_URL}${item?.image}`}
                            alt="Logo"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-9">
                      <div className="card-details">
                        <h3>{item.name}</h3>
                      </div>
                      {item?.type === "percentage" ? (
                        <p className="coupdet">
                          {/* <b>{item.discount}%</b> */}
                          get {item.discount}% off on the minimum order of ₹
                          {item?.minimum_order}
                        </p>
                      ) : (
                        <p className="coupdet">
                          {/* <b>₹ {item.total_amount}</b> */}
                          get ₹ {item.total_amount} off on the minimum order of
                          ₹{item?.minimum_order}
                        </p>
                      )}

                      {/* <div className="border-card"></div> */}

                      <div className="coupancode">
                        <p className="codetxt">
                          Code: <span className="me-2">{item.code}</span>
                          {/* <span>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(item.code)}
                          style={{ cursor: "pointer" }}
                        />
                      </span> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {copied && (
          <span style={{ marginLeft: "5px", alignSelf: "center" }}>Copied</span>
        )}
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
      </Modal.Body>
    </Modal>
  );
};

export default CoupanModal;
